.card {
  margin-bottom: 2.2rem;
  width: 350px;
  min-height: 567px;
  box-shadow: 0px 0px 4px #d4ecff;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--card_bg-color);
  transition: all 0.3s ease;
  box-shadow: 0 5px 20px 1px #00000027;
}
.card:hover {
  transform: scale(1.05);
}

.image img {
  width: 100%;
  height: 260px;
  object-fit: contain;
  border-bottom: 1px solid #c4c2c27c;
}

.card .content {
  padding: 0 1.5rem;
  text-align: start;
}

.content h3 {
  color: var(--card-title);
  font-size: 28px;
  font-weight: 500;
  margin: 10px 0 5px 0;
}

.content .projects-details {
  font-size: 18px;
  color: var(--section_text_color);
  font-weight: lighter;
}
.content .projects-tools {
  color: var(--nav_section-heading);
  font-weight: 500;
  margin: 10px 0;
}
.content .projects-tools span {
  font-size: 13px;
  font-weight: 400;
}

.projects-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0 0 0;
}

.projects-link a {
  color: var(--card_link_color);
}
.project-link-icon {
  color: var(--card_link_color);
}

.project-link-icon {
  font-size: 20px;
  margin-right: 5px;
  margin-bottom: -4px;
}

@media (width < 540px) {
  .card {
    margin-bottom: 2.2rem;
    width: 310px;
    min-height: 480px;
  }
  .image img {
    height: 180px;
  }
  .content h3 {
    font-size: 22px;
  }
  .content .projects-details {
    font-size: 14px;
  }
  .content .projects-tools span {
    font-size: 12px;
  }
  .project-link-icon {
    font-size: 16px;
  }
  .projects-link a {
    font-size: 14px;
  }
}
