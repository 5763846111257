@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--body_background);
  overflow-x: hidden;

  overflow-y: scroll;
}
#root {
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #af39923b;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#642bff, #ff22e6);
  border-radius: 10px;
}

/* media queries  */

@media (width<1300px) {
  .projects-cards {
    justify-content: space-evenly !important;
  }
}
