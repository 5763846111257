.dark_mode {
  margin-top: -23px;
  margin-left: 10px;
}

.dark_mode_label {
  width: 50px;
  height: 28px;
  position: relative;
  display: block;
  background: #f5f5f5;
  border-radius: 200px;
  /* box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0.4); */
  cursor: pointer;
  transition: 0.3s;
}
.dark_mode_label:after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  left: 4.5px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); */
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 45px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 15px;
  top: 7px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  top: 7.5px;
  left: 7.3px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 28px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}

@media (width< 996px) {
  .dark_mode {
    margin-top: -24px;
    margin-left: 10px;
    margin-right: 7px;
  }
  .dark_mode_label {
    width: 50px;
    height: 26px;
  }
  .dark_mode_label:after {
    width: 18px;
    height: 18px;
    top: 4px;
  }
  .dark_mode_label svg {
    width: 15px;
    top: 5px;
  }
  .dark_mode_label svg.sun {
    top: 6.5px;
    left: 7px;
    fill: #fff;
    transition: 0.3s;
  }
  .dark_mode_label svg.moon {
    top: 6px;
    left: 30px;
  }
  .dark_mode_label svg {
    width: 13px;
  }
}

@media (width< 368px) {
  .dark_mode {
    margin-left: -40px;
  }
}
