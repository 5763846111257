.backDrop {
  background-color: rgba(0, 0, 0, 0.511);
  position: fixed;
  top: 20px;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  transition: background-color 0.3s ease-in-out;
}

@media (width> 996px) {
  .backDrop {
    display: none;
  }
}
