.about-section {
  margin-bottom: 300px;
  margin: 120px auto 200px;
  padding-left: 20px;
  padding-right: 20px;
}

#about {
  margin-top: 200px;
}

.about-section h2 {
  font-size: 48px;
  color: var(--nav_section-heading);
  margin-bottom: 20px;
}

.about-section .p-title {
  font-size: 20px;
  color: var(--section_text_color);
  margin-bottom: 10px;
}

.up,
.down {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-me p {
  color: var(--section_text_color);
  text-align: left;
  text-align: justify;
  font-size: 15px;
}
.p-mode {
  background-color: #d7ffe0;
  color: #018c0f;
  font-weight: 600;
  border-radius: 16px;
  font-size: 12px;
  padding: 10px 20px;
}
.down {
  margin-top: 20px;
}
.down p {
  color: var(--section_text_color_2);
  font-size: 14px;
  margin-bottom: 5px;
}

.education-2 {
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #6666663a;
}
.logo {
  font-size: 18px;
  position: relative;
  top: 4px;
  margin-right: 6px;
  color: var(--section_text_color_2);
}

.location {
  margin-left: 120px;
}

.about-me,
.work-experience,
.education {
  margin-bottom: 100px;
}

@media (width<540px) {
  .about-section h2 {
    font-size: 30px;
  }
  .about-section .p-title {
    font-size: 14px;
  }
  .up,
  .down {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .down p {
    font-size: 10px;
  }
  .p-mode {
    font-weight: 500;
    border-radius: 16px;
    font-size: 10px;
    padding: 7px 15px;
  }
}

@media (width<1215px) {
  .about-section {
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
  }
  .up,
  .down {
    align-items: center;
    margin-bottom: 10px;
  }
}
