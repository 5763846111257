:root {
  --body_background: #f5f5f5;
  --nav-bg-color: #ffffff;
  --nav_section-heading: #42446e;
  --section_text_color: #666666;
  --section_text_color_2: #a7a7a7;
  --card_link_color: #000000;
  --card_bg-color: #fff;
  --card-title: #42446e;
  --input_color: #adadad;
  --input_border_color: #cbcbcb;
  --button_bg_color: #f5f5f5;
  --button_hover_color: #d4d4d46c;
  --scroll_btn_color: #42446e;
  --tech_stack_color: black;
}

[data-theme="dark"] {
  --body_background: #191919;
  --nav-bg-color: #000000ea;
  --nav_section-heading: #d9d9d9;
  --section_text_color: #a7a7a7;
  --section_text_color_2: #a7a7a7;
  --card_link_color: #ffffff;
  --card_bg-color: #414141;
  --card-title: #cccccc;
  --input_color: #adadad;
  --input_border_color: #cbcbcb;
  --button_bg_color: #1d1d1d;
  --button_hover_color: #d4d4d46c;
  --scroll_btn_color: #d4d4d46c;
  --tech_stack_color: white;
}

* {
  transition: all 0.2s ease-in-out;
}
